@import "constants";

& {
  background-color: $background-color;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 1rem 0;

    .buttons {
      flex: 1;
      display: flex;
      justify-content: flex-start;

      column-gap: 1.5em;

      a {
        color: $muted-text-color;
        text-decoration: none;

        & + a {
          position: relative;

          &:before {
            content: "•";
            position: absolute;
            left: -1rem;

            color: rgb(0, 0, 0, .25);
          }
        }
      }
    }

    p {
      color: $muted-text-color;
      font-size: 0.8em;

      a {
        color: $muted-text-color;
        text-decoration: none;
      }
    }
  }
}