@import "constants";

& {
  background-color: $background-color;

  .container {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.2em;
      font-weight: normal;

      color: rgb(0, 0, 0, .7);
    }

    .buttons {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      column-gap: 1.5em;

      a {
        color: rgb(0, 0, 0, .55);
        text-decoration: none;
      }
    }
  }
}