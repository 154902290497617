& {
  border-top: .125rem solid rgba(211, 211, 211, 0.2);
  & > .content {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * {
      flex: 1;
    }

    .imageblock img {
      width: 100%;
      height: auto;
    }

    .exampleblock {

      .content {
        padding: 0 7rem;

        .title {
          font-size: 2em;
          font-weight: bold;
          color: rgb(0, 0, 0, .8);

          margin-bottom: .5em;
        }

        .description {
          font-size: 1.2em;
          line-height: 1.8em;
          color: rgb(0, 0, 0, .7);
        }
        p {
          margin: 0;
        }
      }
    }
  }
}